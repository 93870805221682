import React, {useEffect, useState} from "react";
import './treeMultiSelectField.scss'
import {useTranslation} from "react-i18next";
import {
    faChevronRight,
    faPlus, faTimes,
} from "@fortawesome/free-solid-svg-icons";
import IconButtonText from "../../buttons/iconbuttontext/IconButtonText";
import {ThemedH6} from "../../../Elements";
import styled from "styled-components";
import {
    cultured,
    greyLighter,
    greyMedium,
    majorelle,
    openSans,
    roundedBackgroundPointyUpperLeft, spaceCadetLight,
    white
} from "../../../Mixins";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VocabularyPopup2 from "../vocabularypopup/VocabularyPopup2";
import Toaster from "../../../util/toaster/Toaster";
import {GlobalPageMethods} from "../../page/Page";
import Api from "../../../util/api/Api";

export function TreeMultiSelectField(props) {

    const {t} = useTranslation()

    let label = t('language.current_code') === 'nl' ? 'labelNL' : 'labelEN';

    let classAddition = '';
    classAddition += (props.readonly ? ' disabled' : '');
    classAddition += (props.isValid ? ' valid' : '');
    classAddition += (props.hasError ? ' invalid' : '');
    const items = props.formReducerState[props.name] ?? [];

    const toggleAll = {
        0: !props.readonly,
        1: !props.readonly,
        2: !props.readonly,
        3: !props.readonly
    };

    const [options, setOptions] = useState(props.options ? props.options : [])
    const [vocabularies, setVocabularies] = useState([])
    const [vocabularyOptions, setVocabularyOptions] = useState([])
    const [activeVocabulary, setActiveVocabulary] = useState(null)
    const [checkedItems, setCheckedItems] = useState(props.defaultValue ? props.defaultValue : []);
    const [foldedCategories, setFoldedCategories] = useState(props.defaultValue ? toggleAll : {})
    const [expandedItems, setExpandedItems] = useState({}); // Tracks expanded items by level
    const [trail, setTrail] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    let stringifiedDefaultValue = null
    if (!(props.defaultValue === undefined || props.defaultValue === null || props.defaultValue.length === 0)) {
        stringifiedDefaultValue = JSON.stringify(props.defaultValue);
    }

    let stringifiedCurrentValue = null
    if (!(checkedItems === undefined || checkedItems === null || checkedItems.length === 0)) {
        stringifiedCurrentValue = JSON.stringify(checkedItems);
    }

    const findRootOptions = (options, defaultValueIds) => {
        function findRootNodes(array) {
            let rootNodes = [];

            array.forEach(item => {
                if (defaultValueIds.includes(item.id)) {
                    rootNodes.push(item.rootNode);
                }

                if (item.children && item.children.length > 0) {
                    rootNodes = rootNodes.concat(findRootNodes(item.children));
                }
            });

            return rootNodes;
        }

        const matchedRootNodes = findRootNodes(options);

        const matchedOptions = options.filter(option => matchedRootNodes.includes(option.id));

        setVocabularyOptions(matchedOptions);
    };


    useEffect(() => {
        if (props.defaultValue) {
            (async () => {
                try {
                    const response = await getAsyncVocabularyOptions(props, 'null');
                    if (response && Array.isArray(response.data)) {
                        findRootOptions(response.data, props.defaultValue);
                    }
                } catch (error) {
                    console.error("Failed to fetch vocabulary options:", error);
                }
            })();
        }
    }, []);

    useEffect(() => {
        const isDirty = stringifiedDefaultValue !== stringifiedCurrentValue
        props.setValue(props.name, stringifiedCurrentValue, {shouldDirty: isDirty})
    }, [checkedItems]);

    useEffect(() => {
        props.register({
            name: props.name
        }, {
            required: props.isRequired
        })
        props.setValue(props.name, stringifiedCurrentValue)
    }, [props.register]);

    useEffect(() => {
        props.setValue(JSON.stringify(checkedItems))
    }, [checkedItems]);

    const handleFieldSetClick = (selectedItem) => {
        setCheckedItems((prevCheckedItems) => {
            const { id, parentOption } = selectedItem;

            const getAllIds = (item, allIds = []) => {
                allIds.push(item.id);
                if (item.children && item.children.length > 0) {
                    item.children.forEach((child) => getAllIds(child, allIds));
                }
                return allIds;
            };

            const collectParentIds = (currentItem, collectedIds = []) => {
                if (currentItem.parentOption === currentItem.rootNode) {
                    return collectedIds;
                }

                collectedIds.push(currentItem.parentOption);

                const parentItem = findOptionById(currentItem.parentOption, vocabularyOptions.filter(option => option.id === currentItem.rootNode));
                if (parentItem) {
                    return collectParentIds(parentItem, collectedIds);
                }

                return collectedIds;
            };

            if (!prevCheckedItems.includes(id)) {
                let idsToAdd = [id];

                if (parentOption) {
                    const parentIds = collectParentIds(selectedItem);
                    idsToAdd = [...idsToAdd, ...parentIds];
                }

                return [...new Set([...prevCheckedItems, ...idsToAdd])];
            }
            else {
                const idsToRemove = getAllIds(selectedItem);
                return prevCheckedItems.filter((itemId) => !idsToRemove.includes(itemId));
            }
        });
    };


    const updateTrail = (optionId) => {
        let newTrail = [];
        let currentOption = findOptionById(optionId, vocabularyOptions);

        while (currentOption) {
            newTrail.unshift(currentOption.id);
            currentOption = currentOption.parentOption
                ? findOptionById(currentOption.parentOption, vocabularyOptions)
                : null;
        }

        setTrail(newTrail);
    };

    const findOptionById = (id, options) => {
        for (let option of options) {
            if (option.id === id) {
                return option;
            }

            if (option.children && option.children.length > 0) {
                const found = findOptionById(id, option.children);
                if (found) {
                    return found;
                }
            }
        }

        return null;
    };


    const toggleExpanded = (id, level, option) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [level]: prevState[level] === id ? null : id
        }));

        updateTrail(option.id);
    };

    const toggleFolded = (boxId) => {
        setFoldedCategories((prev) => ({
            ...prev,
            [boxId]: !prev[boxId]
        }));
    };

    function setOptionsForVocabulary(value, data, onError){
        const newVocabularyObject = {
            labelNL: value.labelNL,
            labelEN: value.labelEN,
            id: value.id,
            children: data
        };

        const exists = vocabularyOptions.some(item => item.id === newVocabularyObject.id);

        if (!exists) {
            setVocabularyOptions([...vocabularyOptions, newVocabularyObject]);
        } else {
            onError();
        }
    }

    const renderOptions = (options, level = 0, parentId = null) => {
        if (!options || options.length === 0) {
            return null;
        }

        const headings = [
            t("treemultiselect_field.categories.category"),
            t("treemultiselect_field.categories.sub_category"),
            t("treemultiselect_field.categories.sub_sub_category"),
            t("treemultiselect_field.categories.details"),
        ];

        const headingText = headings[level] || t("treemultiselect_field.categories.details");

        // Filter options for the current level
        const filteredOptions = options.filter(option => {
            return level === 0 || option.parentOption === parentId;
        });

        return (
            <React.Fragment key={`level-${level}`}>
                <CategoryBox folded={foldedCategories[level]} onClick={() => toggleFolded(level)}>
                    <h5>{headingText}</h5> {/* Dynamic heading based on level */}
                    <InputContainer onClick={(e) => e.stopPropagation()}>
                        {filteredOptions.map((option) => {
                            const hasChildren = option.children && option.children.length > 0;

                            return (
                                <FieldSet
                                    key={option.id}
                                >
                                    <InputLabelContainer backgroundColor={trail.includes(option.id)} onClick={props.readonly ? null : () => handleFieldSetClick(option)}>
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${option.id}`}
                                            style={{ accentColor: majorelle}}
                                            checked={checkedItems.includes(option.id) || false}
                                            disabled={props.readonly}
                                        />
                                        <label htmlFor={`checkbox-${option.id}`}>
                                            {option.labelNL}
                                        </label>
                                    </InputLabelContainer>

                                    {hasChildren &&
                                        <div style={{padding: "8px"}} onClick={(e) => {
                                            e.stopPropagation(e);
                                            toggleExpanded(option.id, level, option)
                                        }}>
                                            <FontAwesomeIcon style={{width: "10px"}} icon={faChevronRight} />
                                        </div>
                                    }
                                </FieldSet>
                            );
                        })}
                    </InputContainer>
                </CategoryBox>

                {/* Render the next level if an item is expanded */}
                {expandedItems[level] &&
                    filteredOptions.some(option => option.id === expandedItems[level]) &&
                    filteredOptions.map(option => {
                        if (option.id === expandedItems[level] && option.children && option.children.length > 0) {
                            return renderOptions(option.children, level + 1, option.id); // Render the children of the expanded item
                        }
                        return null;
                    })}
            </React.Fragment>
        );
    };


    const MetafieldOptions = ({ options }) => {
        if (!options ) {
            return null;
        }

        const matchedObject = vocabularyOptions.find(item => item.id === activeVocabulary)

        return (
            <FourColumnContainer>
                {matchedObject && renderOptions(matchedObject.children)}
            </FourColumnContainer>
        );
    };

    return (
        <TreeMultiSelectFieldRoot>
                {props.readonly && !(items && items.length > 0) &&
                    <>
                        { props.showEmptyState
                            ?   <EmptyPlaceholder>
                                    <ThemedH6>{props.emptyText}</ThemedH6>
                                </EmptyPlaceholder>
                            :   <div className={"field-input readonly"}>{"-"}</div>
                        }
                    </>
                }

                <div style={{display: "flex", alignItems: "center", gap: "10px", flexDirection: "row", flexWrap: "wrap"}}>
                    {vocabularyOptions && vocabularyOptions.map((tag) => {
                        return (
                            <SelectedVocabularyTag active={activeVocabulary === tag.id} onClick={() => setActiveVocabulary(tag.id)}>
                                <p>{tag.labelNL}</p>
                                {!props.readonly && <DeleteIcon active={activeVocabulary === tag.id} color={majorelle} icon={faTimes} onClick={() => deleteVocabulary(tag)}/>}
                            </SelectedVocabularyTag>
                        )
                    })}

                    {/*Add button*/}
                    {!props.readonly &&
                        <div key={'add-vocabulary-button'} onClick={!isLoading ? () => openVocabularyPopup(vocabularyOptions) : null}>
                            <IconButtonText
                                className={`plus-button with-top-margin ${isLoading ? 'disabled' : ''}`}
                                faIcon={faPlus}
                                buttonText={props.addText}
                            />
                        </div>
                    }
                </div>

                <MetafieldOptions options={options}/>

        </TreeMultiSelectFieldRoot>
    );

    function deleteVocabulary(vocabulary){
        setVocabularyOptions(prevArray => prevArray.filter(item => item.id !== vocabulary.id));

        const collectIds = (items) => {
            let ids = [];
            items.forEach(item => {
                ids.push(item.id);
                if (item.children) {
                    ids = ids.concat(collectIds(item.children));
                }
            });
            return ids;
        };

        const allVocabularyIds = collectIds(vocabulary.children || []);

        setCheckedItems(prevCheckedItems =>
            prevCheckedItems.filter(id => !allVocabularyIds.includes(id))
        );

    }

    function getSelectedItemsFromOptions() {
        return options.filter((o) => items.find((i) => o.value === i));
    }

    function deleteValue(value) {
        const action = {
            type: 'delete',
            value: value
        };
        props.onChange(action)
    }

    function createValue(value) {
        const action = {
            type: 'create',
            value: value
        };
        props.onChange(action)
    }

    function getVocabularyOptions(props, parentOptionId, onSuccess) {

        function onValidate(response) {}
        function onLocalFailure(error) {
            Toaster.showDefaultRequestError()
            GlobalPageMethods.setFullScreenLoading(false)
        }

        function onServerFailure(error) {
            Toaster.showServerError(error)
            if (error.response.status === 401) { //We're not logged, thus try to login and go back to the current url
                props.history.push('/login?redirect=' + window.location.pathname);
            }
            GlobalPageMethods.setFullScreenLoading(false)
        }

        const vocabularyCallConfig = {
            params: {
                "filter[fieldKey]": props.name,
                "filter[ParentOption]": parentOptionId,
                "filter[isRemoved][EQ]": 0,
                "filter[includeChildren][EQ]": 1,
                'page[number]': 1,
                'page[size]': 1000,
            }
        };

        // Sort on label when retainOrder is false
        if (!props.retainOrder) {
            vocabularyCallConfig.params.sort = label
        }

        Api.jsonApiGet('metaFieldOptions', onValidate, onSuccess, onLocalFailure, onServerFailure, vocabularyCallConfig);
    }

    function getAsyncVocabularyOptions(props, parentOptionId) {
        return new Promise((resolve, reject) => {
            function onValidate(response) {}

            function onLocalFailure(error) {
                Toaster.showDefaultRequestError();
                GlobalPageMethods.setFullScreenLoading(false);
                reject(error); // Reject the promise on local failure
            }

            function onServerFailure(error) {
                Toaster.showServerError(error);
                if (error.response.status === 401) {
                    // We're not logged in, so redirect to the login page
                    props.history.push('/login?redirect=' + window.location.pathname);
                }
                GlobalPageMethods.setFullScreenLoading(false);
                reject(error); // Reject the promise on server failure
            }

            const vocabularyCallConfig = {
                params: {
                    "filter[fieldKey]": props.name,
                    "filter[ParentOption]": parentOptionId,
                    "filter[isRemoved][EQ]": 0,
                    "filter[includeChildren][EQ]": 1,
                    'page[number]': 1,
                    'page[size]': 1000,
                }
            };

            if (!props.retainOrder) {
                vocabularyCallConfig.params.sort = label;
            }

            // API call
            Api.jsonApiGet('metaFieldOptions', onValidate, resolve, onLocalFailure, onServerFailure, vocabularyCallConfig);
        });
    }

    function openVocabularyPopup(vocabularyOptions) {
        setIsLoading(true);

        VocabularyPopup2.show(
            props.name,
            props.jsonKey,
            props.label,
            (vocabulary) => {
                if (vocabulary) {
                    getVocabularyOptions(props, vocabulary.id, (response) => {
                        setActiveVocabulary(vocabulary.id);
                        setOptionsForVocabulary(vocabulary, response.data, () => {
                            console.error("already exists");
                            setIsLoading(false);
                        });
                        setIsLoading(false);
                    });
                } else {
                    console.error("No vocabulary selected or vocabulary.id is undefined");
                    setIsLoading(false);
                }
            },
            () => {
                console.log("Popup canceled");
                setIsLoading(false);
            },
            props.retainOrder,
            vocabularyOptions
        );
    }
}

const EmptyPlaceholder = styled.div`
    width: 100%;
    background: ${cultured};
    margin: 20px 0;
    text-align: center;
    padding: 20px 0;
`;

const TreeMultiSelectFieldRoot = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    top: 10px;
`;
//
// const Tag = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     gap: 10px;
//     ${roundedBackgroundPointyUpperLeft(majorelle, '8px')};
//     padding: 12px 13px;
//     flex-shrink: 0;
//     flex-basis: fit-content;
// `;
//
// const Value = styled.div`
//     ${openSans()};
//     font-size: 12px;
//     color: ${white};
// `;
//
// const DeleteButton = styled(FontAwesomeIcon)`
//     font-size: 14px;
//     color: ${white};
//     cursor: pointer;
// `;
//
// const TagContainer = styled.div`
//     background: ${greyMedium};
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     row-gap: 15px;
//     column-gap: 6px;
//     flex-wrap: wrap;
//     margin-top: ${props => props.isEmpty ? "0" : "15px"};
// `;
//
// const AddButton = styled.div`
//     //margin-top: 20px;
// `;

const FourColumnContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    margin-top: 30px;
`

const InputContainer = styled.div`
    height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const CategoryBox = styled.div`
    padding: 10px;
    background-color: ${greyLighter};
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: ${props => props.folded ? '30px' : 'calc(100%/4)'};
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    
    ${InputContainer}{
        display: ${props => props.folded && 'none'};
    }
    
    h5 {
        transform: ${props => props.folded && 'rotate(90deg)'};
        white-space: nowrap;
    }
`

const InputLabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: ${props => props.backgroundColor && '#E7E3F6'};
    border-radius: 10px;
    text-align: start;
    
    &:hover {
        background-color: #E7E3F6;
    }
    
    input[type='checkbox']{
        width: 20px !important;
        height: 20px !important;
        min-width: 20px !important;
        min-height: 20px !important;
        margin-right: 5px;
        margin-left: 0px;
        cursor: pointer;
    }

    label {
        font-size: 13px;
        text-align: start;
        cursor: pointer;
    }`

const FieldSet = styled.fieldset`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    cursor: pointer;
`

const DeleteIcon = styled(FontAwesomeIcon)`
    width: 10px;
    height: 10px;
    color: ${props => props.active ? majorelle : '#000'};
`

const SelectedVocabularyTag = styled.div`
    padding: 5px 10px;
    border: 2px solid;
    border-color: ${props => props.active ? majorelle : greyMedium};
    border-radius: 20px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    &:hover {
        border-color: ${spaceCadetLight};

        p {
            color: ${spaceCadetLight};
        }

        ${DeleteIcon} {
            color: ${spaceCadetLight};
        }
    }

    p {
        font-size: 13px;
        font-weight: 600;
        color: ${props => props.active ? majorelle : greyMedium};
        margin: 0;
    }
`

export const data = [
    {
        id: "2ad48404-d2ca-448b-823c-258db98ea4e1",
        labelNL: "Hogeschool Utrecht",
        labelEN: "University of Applied Sciences Utrecht",
        statistics: {
            submittedPublications: 35000,
            downloads: 4000,
            activeUsers: 32000,
            publicationTypes: [
                {
                    labelNL: "Afstudeerproducten",
                    labelEN: "Publications",
                    value: 10000
                },
                {
                    labelNL: "Leermaterialen",
                    labelEN: "Learning materials",
                    value: 12000
                },
                {
                    labelNL: "Onderzoekspublicaties",
                    labelEN: "Research publications",
                    value: 13000
                }
            ],
            publicationStatuses: [
                {
                    labelNL: "Gepubliceerd",
                    labelEN: "Published",
                    value: 10000
                },
                {
                    labelNL: "Openstaand",
                    labelEN: "Open",
                    value: 12000
                },
                {
                    labelNL: "Embargo",
                    labelEN: "Embargo",
                    value: 13000
                },
                {
                    labelNL: "Concept",
                    labelEN: "Concept",
                    value: 13000
                },
                {
                    labelNL: "Archief",
                    labelEN: "Archived",
                    value: 13000
                }
            ],
            downloadsPerChannel: [
                {
                    channel: "HBO Kennisbank",
                    total: 23000
                },
                {
                    channel: "Hogeschool Inholland",
                    total: 23000
                },
                {
                    channel: "Hogeschool Utrecht",
                    total: 23000
                },
                {
                    channel: "Fontys",
                    total: 10123
                }
            ]
        },
        institutes: [
            {
                type: null,
                id: "9bc007df-82c3-4bcb-9b94-1dfd5d77f9ca",
                title: "Hogeschool Utrecht",
                permissions: {
                    canCreateLearningObject: true,
                    canCreatePublicationRecord: true,
                    canCreateResearchObject: true,
                    canCreateDataset: true,
                    canCreateProject: true,
                    canEdit: true,
                    canDelete: false,
                    canCreateSubInstitute: true
                },
                isRemoved: 0,
                level: "organisation",
                abbreviation: "HU",
                isBaseScopeForUser: true,
                childrenInstitutesCount: 15,
                isHidden: 0,
                publications: 35000
            }
        ]
    },
    {
        id: "c783292a-5a78-4149-bbea-9e9cb392765f",
        labelNL: "Hogeschool Rotterdam",
        labelEN: "Rotterdam University of Applied Sciences",
        statistics: {
            submittedPublications: 45000,
            downloads: 5000,
            activeUsers: 40000,
            publicationTypes: [
                {
                    labelNL: "Afstudeerproducten",
                    labelEN: "Publications",
                    value: 15000
                },
                {
                    labelNL: "Leermaterialen",
                    labelEN: "Learning materials",
                    value: 14000
                },
                {
                    labelNL: "Onderzoekspublicaties",
                    labelEN: "Research publications",
                    value: 16000
                }
            ],
            publicationStatuses: [
                {
                    labelNL: "Gepubliceerd",
                    labelEN: "Published",
                    value: 15000
                },
                {
                    labelNL: "Openstaand",
                    labelEN: "Open",
                    value: 14000
                },
                {
                    labelNL: "Embargo",
                    labelEN: "Embargo",
                    value: 16000
                },
                {
                    labelNL: "Concept",
                    labelEN: "Concept",
                    value: 12000
                },
                {
                    labelNL: "Archief",
                    labelEN: "Archived",
                    value: 13000
                }
            ],
            downloadsPerChannel: [
                {
                    channel: "HBO Kennisbank",
                    total: 25000
                },
                {
                    channel: "Hogeschool Rotterdam",
                    total: 24000
                },
                {
                    channel: "Fontys",
                    total: 15000
                }
            ]
        },
        institutes: [
            {
                type: null,
                id: "9bc007df-82c3-4bcb-9b94-1dfd5d77f9ca",
                title: "Hogeschool Utrecht",
                permissions: {
                    canCreateLearningObject: true,
                    canCreatePublicationRecord: true,
                    canCreateResearchObject: true,
                    canCreateDataset: true,
                    canCreateProject: true,
                    canEdit: true,
                    canDelete: false,
                    canCreateSubInstitute: true
                },
                isRemoved: 0,
                level: "organisation",
                abbreviation: "HU",
                isBaseScopeForUser: true,
                childrenInstitutesCount: 98,
                isHidden: 0,
                publications: 20000
            }
        ]
    },
    {
        id: "39a22a4a-df7a-4ed0-93e6-16b7be163e90",
        labelNL: "Hogeschool van Amsterdam",
        labelEN: "Amsterdam University of Applied Sciences",
        statistics: {
            submittedPublications: 36000,
            downloads: 4100,
            activeUsers: 30000,
            publicationTypes: [
                {
                    labelNL: "Afstudeerproducten",
                    labelEN: "Publications",
                    value: 12000
                },
                {
                    labelNL: "Leermaterialen",
                    labelEN: "Learning materials",
                    value: 11000
                },
                {
                    labelNL: "Onderzoekspublicaties",
                    labelEN: "Research publications",
                    value: 13000
                }
            ],
            publicationStatuses: [
                {
                    labelNL: "Gepubliceerd",
                    labelEN: "Published",
                    value: 13000
                },
                {
                    labelNL: "Openstaand",
                    labelEN: "Open",
                    value: 9000
                },
                {
                    labelNL: "Embargo",
                    labelEN: "Embargo",
                    value: 14000
                },
                {
                    labelNL: "Concept",
                    labelEN: "Concept",
                    value: 12000
                },
                {
                    labelNL: "Archief",
                    labelEN: "Archived",
                    value: 11000
                }
            ],
            downloadsPerChannel: [
                {
                    channel: "HBO Kennisbank",
                    total: 24000
                },
                {
                    channel: "Hogeschool van Amsterdam",
                    total: 23000
                },
                {
                    channel: "Fontys",
                    total: 11000
                }
            ]
        },
        institutes: [
            {
                type: null,
                id: "9bc007df-82c3-4bcb-9b94-1dfd5d77f9ca",
                title: "Hogeschool Utrecht",
                permissions: {
                    canCreateLearningObject: true,
                    canCreatePublicationRecord: true,
                    canCreateResearchObject: true,
                    canCreateDataset: true,
                    canCreateProject: true,
                    canEdit: true,
                    canDelete: false,
                    canCreateSubInstitute: true
                },
                isRemoved: 0,
                level: "organisation",
                abbreviation: "HU",
                isBaseScopeForUser: true,
                childrenInstitutesCount: 98,
                isHidden: 0,
                publications: 20000
            }
        ]
    },
    {
        id: "847c5627-48d4-4e10-afe1-cc97f2f3a964",
        labelNL: "Saxion Hogeschool",
        labelEN: "Saxion University of Applied Sciences",
        statistics: {
            submittedPublications: 38000,
            downloads: 4500,
            activeUsers: 37000,
            publicationTypes: [
                {
                    labelNL: "Afstudeerproducten",
                    labelEN: "Publications",
                    value: 13000
                },
                {
                    labelNL: "Leermaterialen",
                    labelEN: "Learning materials",
                    value: 14000
                },
                {
                    labelNL: "Onderzoekspublicaties",
                    labelEN: "Research publications",
                    value: 11000
                }
            ],
            publicationStatuses: [
                {
                    labelNL: "Gepubliceerd",
                    labelEN: "Published",
                    value: 15000
                },
                {
                    labelNL: "Openstaand",
                    labelEN: "Open",
                    value: 13000
                },
                {
                    labelNL: "Embargo",
                    labelEN: "Embargo",
                    value: 10000
                },
                {
                    labelNL: "Concept",
                    labelEN: "Concept",
                    value: 12000
                },
                {
                    labelNL: "Archief",
                    labelEN: "Archived",
                    value: 14000
                }
            ],
            downloadsPerChannel: [
                {
                    channel: "HBO Kennisbank",
                    total: 27000
                },
                {
                    channel: "Saxion Hogeschool",
                    total: 26000
                },
                {
                    channel: "Fontys",
                    total: 13000
                }
            ]
        },
        institutes: [
            {
                type: null,
                id: "9bc007df-82c3-4bcb-9b94-1dfd5d77f9ca",
                title: "Hogeschool Utrecht",
                permissions: {
                    canCreateLearningObject: true,
                    canCreatePublicationRecord: true,
                    canCreateResearchObject: true,
                    canCreateDataset: true,
                    canCreateProject: true,
                    canEdit: true,
                    canDelete: false,
                    canCreateSubInstitute: true
                },
                isRemoved: 0,
                level: "organisation",
                abbreviation: "HU",
                isBaseScopeForUser: true,
                childrenInstitutesCount: 98,
                isHidden: 0,
                publications: 20000
            }
        ]
    },
    {
        id: "60e4cb08-965b-4cb9-8c0f-58f2972f6f9c",
        labelNL: "Hogeschool Leiden",
        labelEN: "Leiden University of Applied Sciences",
        statistics: {
            submittedPublications: 33000,
            downloads: 3800,
            activeUsers: 29000,
            publicationTypes: [
                {
                    labelNL: "Afstudeerproducten",
                    labelEN: "Publications",
                    value: 11000
                },
                {
                    labelNL: "Leermaterialen",
                    labelEN: "Learning materials",
                    value: 12000
                },
                {
                    labelNL: "Onderzoekspublicaties",
                    labelEN: "Research publications",
                    value: 10000
                }
            ],
            publicationStatuses: [
                {
                    labelNL: "Gepubliceerd",
                    labelEN: "Published",
                    value: 12000
                },
                {
                    labelNL: "Openstaand",
                    labelEN: "Open",
                    value: 10000
                },
                {
                    labelNL: "Embargo",
                    labelEN: "Embargo",
                    value: 11000
                },
                {
                    labelNL: "Concept",
                    labelEN: "Concept",
                    value: 9000
                },
                {
                    labelNL: "Archief",
                    labelEN: "Archived",
                    value: 11000
                }
            ],
            downloadsPerChannel: [
                {
                    channel: "HBO Kennisbank",
                    total: 20000
                },
                {
                    channel: "Hogeschool Leiden",
                    total: 19000
                },
                {
                    channel: "Fontys",
                    total: 8000
                }
            ]
        },
        institutes: [
            {
                type: null,
                id: "9bc007df-82c3-4bcb-9b94-1dfd5d77f9ca",
                title: "Hogeschool Utrecht",
                permissions: {
                    canCreateLearningObject: true,
                    canCreatePublicationRecord: true,
                    canCreateResearchObject: true,
                    canCreateDataset: true,
                    canCreateProject: true,
                    canEdit: true,
                    canDelete: false,
                    canCreateSubInstitute: true
                },
                isRemoved: 0,
                level: "organisation",
                abbreviation: "HU",
                isBaseScopeForUser: true,
                childrenInstitutesCount: 98,
                isHidden: 0,
                publications: 20000
            }
        ]
    },
    {
        id: "b5e2ab59-2e2f-489b-81b1-8272ccf9a9f4",
        labelNL: "Avans Hogeschool",
        labelEN: "Avans University of Applied Sciences",
        statistics: {
            submittedPublications: 42000,
            downloads: 4800,
            activeUsers: 41000,
            publicationTypes: [
                {
                    labelNL: "Afstudeerproducten",
                    labelEN: "Publications",
                    value: 16000
                },
                {
                    labelNL: "Leermaterialen",
                    labelEN: "Learning materials",
                    value: 13000
                },
                {
                    labelNL: "Onderzoekspublicaties",
                    labelEN: "Research publications",
                    value: 13000
                }
            ],
            publicationStatuses: [
                {
                    labelNL: "Gepubliceerd",
                    labelEN: "Published",
                    value: 17000
                },
                {
                    labelNL: "Openstaand",
                    labelEN: "Open",
                    value: 13000
                },
                {
                    labelNL: "Embargo",
                    labelEN: "Embargo",
                    value: 14000
                },
                {
                    labelNL: "Concept",
                    labelEN: "Concept",
                    value: 15000
                },
                {
                    labelNL: "Archief",
                    labelEN: "Archived",
                    value: 16000
                }
            ],
            downloadsPerChannel: [
                {
                    channel: "HBO Kennisbank",
                    total: 29000
                },
                {
                    channel: "Avans Hogeschool",
                    total: 28000
                },
                {
                    channel: "Fontys",
                    total: 14000
                }
            ]
        },
        institutes: [
            {
                type: null,
                id: "9bc007df-82c3-4bcb-9b94-1dfd5d77f9ca",
                title: "Hogeschool Utrecht",
                permissions: {
                    canCreateLearningObject: true,
                    canCreatePublicationRecord: true,
                    canCreateResearchObject: true,
                    canCreateDataset: true,
                    canCreateProject: true,
                    canEdit: true,
                    canDelete: false,
                    canCreateSubInstitute: true
                },
                isRemoved: 0,
                level: "organisation",
                abbreviation: "HU",
                isBaseScopeForUser: true,
                childrenInstitutesCount: 98,
                isHidden: 0,
                publications: 20000
            }
        ]
    }
]
